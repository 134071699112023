// 
// _card.scss
// 

.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: $box-shadow;
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;  
}

.wcard-schuman {
    background-image: url('../../../images/back-schumann.png');
    background-size: cover;
    background-position: center;
    color: #FFFFFF;
    min-height: 150px;
}

.wcard-client {
    background: rgb(226, 226, 226);
    background: linear-gradient(122deg, rgb(105, 105, 105) 0%, rgb(214, 214, 214) 29%, rgb(241, 241, 241) 100%);
    position: relative;
}

.wcard-client img{
  display: block;
  margin: 10px auto;
  max-width: 180px;
}


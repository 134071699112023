
@font-face {
  font-family: 'Poppins';
  src: local('Poppins-regular'),
    url('Poppins-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins-black'),
    url('Poppins-Black.ttf') format('truetype');
    font-weight: 900;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins-extrabold'),
    url('Poppins-ExtraBold.ttf') format('truetype');
    font-weight: 800;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins-semibold'),
    url('Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins-bold'), url('Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins-medium'),
    url('Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
}
